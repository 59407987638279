import { is2021Plan, isEnterprisePlan, isExpiredTrialOrCanceled, isSeatLimitReached } from '@gonfalon/billing';
import { useAccountContext } from '@gonfalon/context';
import {
  enableEnterpriseSeatExpansions,
  enableNewInviteMembersPermissionsLogic,
  isEmailVerificationRequired,
  isSeatLimitEnforced,
  isTeamsEnabled,
} from '@gonfalon/dogfood-flags';
import { hasAdminRights, hasCreateMemberRights } from '@gonfalon/members';
import { getSamlConfigQuery, getSubscriptionUsageQuery } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';

export function useCanInviteMembers() {
  const enforceSeatLimit = isSeatLimitEnforced();
  const emailVerificationRequired = isEmailVerificationRequired();
  const isEnterpriseSeatExpansionsEnabled = enableEnterpriseSeatExpansions();
  const enableTeams = isTeamsEnabled();

  const { subscription, profile, isLegacySubscription } = useAccountContext();
  const { data: samlConfig } = useQuery(getSamlConfigQuery({}));
  const { data: subscriptionUsage } = useQuery({
    ...getSubscriptionUsageQuery({}),
    enabled: !isLegacySubscription && enforceSeatLimit,
  });

  const hasSSO = (samlConfig?.enabled && samlConfig?.requireSso) ?? false;
  const atSeatLimit = !!subscriptionUsage ? isSeatLimitReached(subscription, subscriptionUsage) : false;
  const isEmailVerificationPending = emailVerificationRequired && !profile._verified;
  const isSubscriptionAnExpiredTrialOrCanceled = isExpiredTrialOrCanceled(subscription);
  const isEnterprise = isEnterprisePlan(subscription);
  const profileCanInviteMembers = enableNewInviteMembersPermissionsLogic()
    ? hasCreateMemberRights(profile, profile)
    : hasAdminRights(profile);

  const renderRequestSeats = isEnterprise && !!atSeatLimit && isEnterpriseSeatExpansionsEnabled;
  const canInviteMembers = [
    profileCanInviteMembers,
    !hasSSO,
    !isEmailVerificationPending,
    !is2021Plan(subscription) ? !atSeatLimit : true,
    enableTeams,
  ].every((v) => !!v);
  const shouldDisableButton = hasSSO || isSubscriptionAnExpiredTrialOrCanceled;
  const disabledReasons = [
    ...(hasSSO ? ['SSO accounts cannot invite members'] : []),
    ...(isSubscriptionAnExpiredTrialOrCanceled ? ['Your subscription is expired or canceled'] : []),
  ];

  return {
    canInviteMembers,
    renderRequestSeats,
    shouldDisableButton,
    disabledReasons,
    hasSSO,
  };
}
